<template>
    <div>

        <div style="display: flex;justify-content: center;">
            <div style="width: 1520px;">
                <div style="text-align: left;margin-top: 20px;">
                    <el-breadcrumb separator="/" style="padding-bottom: 20px;font-size: 18px!important;">
                        <el-breadcrumb-item :to="{ path: '/enterprise/home' }" style="color: #FFFFFF;">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/combinedQuery/combinedForm' }">组合查询</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/combinedQuery/combinedList' }">查询列表</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
             

                <div style="margin-top: 20px;">

                    <div style="text-align: left;margin-bottom: 20px;">
                        <span style="font-size: 20px;font-weight: bold;">共<span
                                style="color:#DC5800 ;">{{total}}</span>条项目数据</span>
                    </div>

                    <div v-if="loadingStatus" style="height:400px;"></div>

                    <el-table  v-loading="loadingStatus"  border :data="tableData" :header-cell-style=" { background: '#F8F8F8' }">
                        <el-table-column type="index" label="序号" width="80" align="center"
                            label-class-name="table-col-header-label">
                            <template slot-scope="{ $index }">
                                <span>{{ computeIndex($index, currentPage, size) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column header-align="center" align="center" label="社会统一信用代码" prop="creditCode"
                            width="200" min-width="200" label-class-name="table-col-header-label">
                        </el-table-column>


                        <el-table-column header-align="name" align="center" label="企业名称" prop="name"
                            label-class-name="table-col-header-label">
                            <template slot-scope="{ row }">
                                <span @click="goDetail(row)"
                                    style="text-decoration: underline;color: #2180EF; cursor: pointer;">{{row.name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column header-align="name" align="center" label="企业注册属地" prop="address"
                            label-class-name="table-col-header-label">
                            <template slot-scope="{ row }">
                                <div>{{row|showCity()}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column header-align="center" align="center" label="注册资本" prop="registCapi"
                            width="200" min-width="200" label-class-name="table-col-header-label">
                        </el-table-column>
                        <el-table-column header-align="name" align="center" label="企业资质类型" prop="qualification"
                            label-class-name="table-col-header-label">
                            <template slot-scope="{ row }">
                                <span class="hideline1">{{row.qualification}}</span>
                            </template>
                        </el-table-column>

                         <div slot="empty">
                            <el-empty description="暂无数据"></el-empty>
                        </div>
                       

                    </el-table>
                    <div style="text-align: right; margin-top: 20px;margin-bottom: 20px;">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 50, 100]" :page-size="size"
                            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                    </div>
                </div>

            </div>
        </div>



        <quaDialog ref="quaDialog" :selectQuaList="selectQuaList"
            :categoryOptions="qualifiTypeCate1.filter(item=>item!='全部')" @confirm="quaConfirm">
        </quaDialog>
    </div>

</template>

<script>



    import { getAppidIsTree, getCommonQualificationTypes, compositePage } from "@/api/bid.js"
    import selectArea from "@/components/select-area/index.vue";
    // import BannerTitleItem from '../components/banner-title-item/index.vue'
    import selectQua from '@/components//select-qua/index.vue'
    import quaDialog from "@/components/qualification-dialog";
    // import creditScore from './componets/creditScore'
    export default {
        components: {
            // BannerTitleItem,
            selectArea,
            selectQua,
            quaDialog,
            // creditScore
        },
        data() {
            return {
                radio: 1,
                typeList: [{ name: '企业资质', value: '' }, { name: '企业业绩', value: '' }, { name: '企业人员', value: '' }, { name: '信用评级', value: '' }],
                typeIndex: 0,
                //地址组件
                cascaderProps: {
                    lazy: false,
                    value: "adCode",
                    label: "name",
                    children: "child",
                    expandTrigger: "hover",
                    emitPath: true,
                    checkStrictly: true,
                },
                //资质搜索相关---start
                qualifiTypeCate1: [],
                quaTypeName: '全部',
                selectQuaList: [],//选中的专业资质
                province: '',
                capital: '',
                city: '',
                county: '',
                province: '',
                scope: '',
                quaCondition: 0,//0 同时具备 1任意均可
                //资质搜索相关---end

                //企业业绩
                performanceFormData: {
                    startAllinvest: null,
                    endAllinvest: null,
                    andPrjname: null,

                },
                //人员资质---start
                timeout: null,
                allPersonType: [],
                personTypeList: [],
                personIndex: '',
                secondTypeList: [],
                personTypeName: '',
                secondTypeName: '',
                firstName: '',
                personCondition: 0,
                selectPersonList: [],
                //人员资质---end

                //信用分
                scoreReqVO: {
                    creditGrade: '',
                    endScore: '',
                    fromCity: '',
                    fromProvince: '',
                    quarter: '',
                    startScore: '',
                    subjectSpeciality: '',
                    year: '',
                },
                personQualifi: '',
                personQualifiList: [],
                //中标时间
                bidTimeArray: [],
                qualifiType: [],
                qualifiTypeList: ['不限', '四库一平台', '公共资源', '政府采购', '央企招标', '其他'],

                conditionList: [],//条件列表

                loadingStatus: false,
                tableData: [],
                total: 0,
                currentPage: 1,
                size: 10,
                scrollViewY: '',
                params:'',
            };
        },

        filters: {
            //数据问题只能写死判断 可能还有其他奇怪的名称
            showCity(item) {
                
                if (item.province === '北京市省' || item.province === '北京市') {
                    return '北京市' + (item.county ? '-' + item.county : '')
                }  else if (item.province === '天津市省' || item.province === '天津市') {
                    return '天津市' + (item.county ? '-' + item.county : '')
                } else if (item.province === '重庆市省' || item.province === '重庆市') {
                    return '重庆市' + (item.county ? '-' + item.county : '')
                }else if (item.province === '上海市省' || item.province === '上海市') {
                    return '上海市' + (item.county ? '-' + item.county : '')
                }  else {
                    return item.province + (item.city ? '-' + item.city : '') + (item.county ? '-' + item.county : '')
                }
            },
            showSelectCity(){

            },
        },
        created() {
            this.getAppidIsTree()
            this.getQuaType()
            this.params=this.$route.query?.params
        },
        mounted() {
            this.queryAll()
        },
        methods: {
            selectType({ item, index }) {
                this.typeIndex = index
                let elid = 'tabContent' + index
                let target = document.getElementById(elid);
                let parent = document.getElementById('scrollViewY');
                parent.scrollTop = target.offsetTop - parent.offsetTop;
            },
            goDetail(item) {
                console.log('item', item)
                this.$router.push({
                    path: '/qualification/detail',
                    query: { unicode: item.creditCode, },
                });
            },
            computeIndex(index, page, pageSize) {
                return index + 1 + (page - 1) * pageSize;
            },
            handleSizeChange(val) {
                this.size = val
                this.queryAll();
            },
            handleCurrentChange(val) {
                this.currentPage = val

                console.log('=== this.currentPage', this.currentPage)
                this.queryAll();
            },
            //重置
            resetData() {
                //右侧列表查询条件
                this.conditionList = []
                //资质
                this.$refs.selectAreasercher.value = []
                this.$refs.selectQua.personQualifi = ''
                this.$refs.quaDialog.handleReset()
                this.province = ''
                this.city = ''
                this.county = ''
                this.capital = ''
                this.scope = ''
                this.selectQuaList = []
                this.quaCondition = 0
                //业绩
                this.performanceFormData.andPrjname = null
                this.performanceFormData.startAllinvest = null
                this.performanceFormData.endAllinvest = null
                //人员
                this.personIndex = ''
                this.secondTypeList = []
                this.personTypeName = ''
                this.personCondition = 0
                this.selectPersonList = []
                this.firstName = ''
                this.secondTypeName = ''
                //信用分
                this.scoreReqVO.creditGrade = ''
                this.scoreReqVO.endScore = ''
                this.scoreReqVO.fromCity = ''
                this.scoreReqVO.fromProvince = ''
                this.scoreReqVO.quarter = ''
                this.scoreReqVO.startScore = ''
                this.scoreReqVO.subjectSpeciality = ''
                this.scoreReqVO.year = ''

                this.$refs.creditScore.retSetAllData()
                this.currentPage = 1
                this.size = 20
                this.queryAll()

            },
            queryCheck() {
                if (!this.conditionList.length) return this.$message({
                    message: '请选择查询条件!',
                    type: 'warning',
                })
                this.queryAll()
            },
            //开始查询
            async queryAll() {
                try {

                    //资质
                    let quanReqVO = {}
                    let province = ''
                    let city = ''
                    let county = ''
                    let scope = ''
                    let capital = ''
                    let quIndex = this.conditionList.findIndex(item => item.condtionType === 'companyQua')
                    if (quIndex != -1) {
                        let quObj = this.conditionList[quIndex]
                        province = quObj.province
                        city = quObj.city
                        county = quObj.county
                        scope = quObj.scope
                        capital = quObj.capital
                        let quaList = quObj.selectQuaList.map(item => {
                            return item.name
                        })
                        if (quObj.quaCondition === 0) {
                            quanReqVO.andNameList = quaList
                        } else {
                            quanReqVO.orNameList = quaList
                        }
                    }
                    //业绩 注意业绩金额 这个格式不能是空字符 
                    let projectInfoVO = {
                        orPrjname: [],//任意
                        startAllinvest: null,
                        endAllinvest: null,
                    }
                    let projectIndex = this.conditionList.findIndex(item => item.condtionType === 'performance')
                    if (projectIndex != -1) {
                        let performanceObj = this.conditionList[projectIndex]
                        projectInfoVO.orPrjname = performanceObj.andPrjname ? [performanceObj.andPrjname] : []
                        projectInfoVO.startAllinvest = performanceObj.startAllinvest || null
                        projectInfoVO.endAllinvest = performanceObj.endAllinvest || null
                    }

                    //人员
                    let personVO = {

                    }
                    let personIndex = this.conditionList.findIndex(item => item.condtionType === 'person')
                    if (personIndex != -1) {
                        let personObj = this.conditionList[personIndex]
                        if (personObj?.selectPersonList?.length) {

                            if (this.personCondition === 0) {
                                personVO.andTypeNames = personObj.selectPersonList
                            } else {
                                personVO.orTypeNames = personObj.selectPersonList
                            }

                        }
                    }
                    let scoreReqVO = {}

                    let {
                        creditGrade,
                        endScore,
                        fromCity,
                        fromProvince,
                        quarter,
                        startScore,
                        subjectSpeciality,
                        year,
                    } = this.scoreReqVO
                    scoreReqVO = {
                        creditGrade,
                        endScore,
                        fromCity,
                        fromProvince,
                        quarter,
                        startScore,
                        subjectSpeciality,
                        year,
                    }
                    // let params = {
                    //     current: this.currentPage,
                    //     size: this.size,
                    //     province: province,
                    //     city: city,
                    //     county: county,
                    //     scope: scope,
                    //     capital: capital,
                    //     quanReqVO,
                    //     projectInfoVO,
                    //     personVO,
                    //     scoreReqVO,

                    // }
                    this.loadingStatus = true
                    
                    let newParams={
                        current: this.currentPage,
                        size: this.size,
                        ...this.params
                    }
                    let { data } = await compositePage(newParams)

                    this.tableData = data.records
                    this.total = data.total
                    this.loadingStatus = false
                } catch (error) {
                    this.loadingStatus = false
                    console.log('=======error查询异常', error)

                }
            },
            //加入左侧组合查询
            addCondtion(type, creditFrom) {
                let index = this.checkList(type)
                switch (type) {
                    case 'companyQua':

                        let obj = {
                            condtionType: type,
                            province: this.province,
                            city: this.city,
                            county: this.county,
                            address: this.province + (this.city?'-'+this.city:'') + (this.county?'-'+this.county:''),
                            scope: this.scope,
                            selectQuaList: JSON.parse(JSON.stringify(this.selectQuaList)),
                            quaCondition: this.quaCondition,
                            capital: this.capital,
                        }


                        if (this.checkObj(obj)) return this.$message({
                            message: '请添加查询条件!',
                            type: 'warning',
                            center: true
                        });
                        if (index === -1) {
                            this.conditionList.push(obj)
                        } else {
                            this.$set(this.conditionList, index, obj)
                        }

                        break;

                    case 'performance':
                        let performanceObj = {
                            condtionType: type,
                            startAllinvest: this.performanceFormData.startAllinvest,
                            endAllinvest: this.performanceFormData.endAllinvest,
                            andPrjname: this.performanceFormData.andPrjname
                        }
                        if (!this.performanceFormData.startAllinvest && !this.performanceFormData.endAllinvest && !this.performanceFormData.andPrjname) return this.$message({
                            message: '请添加查询条件!',
                            type: 'warning',
                            center: true
                        });
                        if (this.performanceFormData.startAllinvest && (!this.performanceFormData.endAllinvest || this.performanceFormData.endAllinvest === 0)) {
                            let message = !this.performanceFormData.endAllinvest ? '请完善金额!' : '请输入正确的金额!'
                            return this.$message({
                                message: message,
                                type: 'warning',
                                center: true
                            });
                        }
                        if (this.performanceFormData.endAllinvest && (!this.performanceFormData.startAllinvest && this.performanceFormData.startAllinvest !== 0)) {

                            return this.$message({
                                message: '请完善金额!',
                                type: 'warning',
                                center: true
                            });
                        }

                        // if ((this.performanceFormData.endAllinvest <= this.performanceFormData.startAllinvest)) {
                        //     return this.$message({
                        //         message: '请输入正确的金额!',
                        //         type: 'warning',
                        //         center: true
                        //     });
                        // }

                        if (index === -1) {
                            this.conditionList.push(performanceObj)
                        } else {
                            this.$set(this.conditionList, index, performanceObj)

                        }

                        break;

                    case 'person':
                        let personObj = {
                            condtionType: type,
                            selectPersonList: this.selectPersonList.length ? JSON.parse(JSON.stringify(this.selectPersonList)) : []
                        }
                        if (!personObj.selectPersonList.length) return this.$message({
                            message: '请添加查询条件!',
                            type: 'warning',
                            center: true
                        });
                        if (index === -1) {
                            this.conditionList.push(personObj)
                        } else {
                            this.$set(this.conditionList, index, personObj)

                        }

                        break;

                    case 'credit':

                        console.log('credit----', creditFrom)
                        let credit = {
                            condtionType: type,
                            creditGrade: creditFrom.creditGrade,
                            endScore: creditFrom.endScore,
                            fromCity: creditFrom.fromCity,
                            fromProvince: creditFrom.fromProvince,
                            quarter: creditFrom.quarter,
                            startScore: creditFrom.startScore,
                            subjectSpeciality: creditFrom.subjectSpeciality,
                            year: creditFrom.year,
                        }
                        this.scoreReqVO = { ...creditFrom }

                        if (index === -1) {
                            this.conditionList.push(credit)
                        } else {
                            this.$set(this.conditionList, index, credit)

                        }

                        break;


                    default:
                        break;
                }
            },
            //校验存在
            checkList(type) {
                let index = this.conditionList.findIndex(item => item.condtionType === type)
                return index
            },
            checkObj(obj) {
                let value = Object.values(obj)
                let valueLength = value.filter(item => item != '')
             
                if (valueLength.length === 1) {
                    return true
                } else {
                    return false
                }
            },
            //企业资质---start
            quaConfirm({ memorySelected }) {
                memorySelected.forEach(item => {             
                    let index = this.selectQuaList.findIndex(citem => citem.name === item.name)
                    if (index === -1) {
                        this.selectQuaList.push(item)
                    }
                })


            },
            //删除资质
            deleteItem({ index, item }) {
                this.selectQuaList.splice(index, 1)
                this.$refs.quaDialog.deleteItem({ index, item })
            },
            //打开选择窗口
            openQuaSelectBox() {
                this.$refs.quaDialog.category = this.quaTypeName === '全部' ? '' : this.quaTypeName
                this.$refs.quaDialog.dialogVisible = true
                this.$refs.quaDialog.setOpen()
            },
            //输入搜索框 返回资质类别
            backQuaValue(item) {
                let subString = item.name.split(/甲级|乙级|丙级|丁级|甲（Ⅰ）级|甲（Ⅱ）级|特级|一级|二级|三级/)
                let sublength = subString[0].length
                let levelName = subString[0]
                let subStringLevel = item.name.substr(sublength, subString.length)
                console.log('levelName', levelName, subStringLevel)
                for (let i = 0; i < this.selectQuaList.length; i++) {
                    if (this.selectQuaList[i].name.includes(levelName) && this.selectQuaList[i].name != item.name) {

                        return this.$message.warning("同种专业,等级不能多选!");
                    }
                }
                item.select = true
                let index = this.selectQuaList.findIndex(citem => citem.name === item.name)
                if (index === -1) {
                    this.selectQuaList.push(item)
                }

                console.log('====this.selectQuaList', this.selectQuaList)
            },
            //选择一级类型
            slectQuType({ item, index }) {
                this.quaTypeName = item

                console.log('=this.quaTypeName', this)
            },
            //获取一级资质类型
            async getQuaType() {
                try {
                    let { data } = await getCommonQualificationTypes();
                    this.qualifiTypeCate1 = ['全部'].concat(data || [])
                    console.log('资质类型', data)
                } catch (error) {

                }
            },
            //选择人员资质
            handleSelectPerson(item) {

                let index = this.selectPersonList.findIndex(citem => citem === item.name)
                if (index === -1) {
                    this.selectPersonList.push(item.name)
                }
                console.log('=======item', item.name)
            },
            //二级选择
            selectSecondTypeName(typeName) {
                let index = this.selectPersonList.findIndex(citem => citem === typeName)
                if (index === -1) {
                    this.selectPersonList.push(typeName)
                }
            },
            personChange(val) {
                this.firstName = val
                this.secondTypeName = ''
                this.personIndex = val
                let index = this.personTypeList.findIndex(item => item.name === val)
                this.secondTypeList = this.personTypeList[index].child
                let cindex = this.selectPersonList.findIndex(citem => citem === val)
                if (cindex === -1) {
                    this.selectPersonList.push(val)
                }
               

            },
            deletePerson(index) {
                this.selectPersonList.splice(index, 1)
                this.secondTypeName=''
                this.firstName=''
                this.secondTypeList=[]
            },
            //获取人员资质类别
            async getAppidIsTree() {
                try {
                    let { data } = await getAppidIsTree({ appid: 'wxbd94b37dd45c1f91' })
                    let list = data.filter(item => item.label == '人员类别')
                    let allList = []
                    list.forEach((item) => {
                        if (item?.child?.length) {
                            item.child.forEach((citem) => {
                                citem.value = citem.name
                                allList.push(citem)
                            })

                        }
                    })
                    this.allPersonType = allList
                    this.personTypeList = list
                } catch (error) {

                }
            },
            //搜索远端数据
            queryPersonAsync(queryString, cb) {
                try {
                    let results = this.allPersonType.filter(item => item.name.includes(queryString))
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        cb(results);
                    }, 1000 * Math.random());
                    cb(data.records)
                } catch (error) {

                }

            },



            //地址回调
            onCallBack(e) {
                console.log("====e", e);
                if (e.length === 1) {

                    this.province = e[0].name;
                    this.city = "";
                    this.county = "";

                } else if (e.length === 2) {
                    this.city = e[1].name;
                    this.county = "";
                } else if (e.length === 3) {
                    this.province = e[0].name;
                    this.city = e[1].name;
                    this.county = e[2].name;

                } else if (e.length === 0) {
                    this.province = "";
                    this.city = "";
                    this.county = "";
                }
            },

            //时间触发
            changeTimeArray(e) {
                console.log('=======time', e)
            },

        },
    };
</script>

<style scoped>
    .home-banner-bg {
        position: relative;
        background-image: url("~@/assets/images/enterprise/query_bg.png");
        height: 200px;
        width: 100%;
        padding-top: 80px;
    }

    .queryBox {
        border: 1px solid #E2E5EC;

    }

    .bottomTab {
        position: absolute;
        bottom: 0;
        text-align: center;
    }

    .active {
        color: #1890FF;
        border-bottom: solid 3px #1890FF;

    }

    .reset {
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #1890FF;
        color: #1890FF;
        padding: 8px 26px;
        font-size: 14px;
        cursor: pointer;
    }

    .sure {
        color: #FFFFFF;
        background: #1890FF;
        border-radius: 2px 2px 2px 2px;
        padding: 8px 26px;
        font-size: 14px;
        cursor: pointer;
    }

    .scrollViewY {
        overflow: hidden;
        overflow: scroll;
        overflow-x: hidden;
        /* height: 500px; */

        padding: 20px;
    }

    .scrollViewY::-webkit-scrollbar {
        width: 10px;

    }

    .scrollViewY::-webkit-scrollbar-thumb {
        background: #888888;
        border-radius: 30px;
    }

    .red {
        color: #EE1E1E;
        width: 6px;
        height: 6px;
        padding-top: 2px;
        margin-left: 8px;
        margin-right: 20px;
    }

    .quaType {

        cursor: pointer;
    }

    .quaType:hover {
        color: #1890FF;

    }

    .quaTypeActive {
        padding: 8px 10px;
        background: #edf4ff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        color: #007EF2;
    }

    ::v-deep .table-col-header-label {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular;
        font-weight: 500;
        color: #4e4e4e;
        text-align: center;
    }

    .link-in-row.el-link.el-link--default {
        font-size: 16px;
    }

    .link-in-row.el-link.el-link--default:hover {
        color: #007ef2;
    }

    ::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
        font-size: 16px !important;
    }

    .hideline1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>